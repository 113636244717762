import { createWebHistory, createRouter } from 'vue-router';
import Routes from '@/router/routes';

const router = createRouter({
	history: createWebHistory('/'),
	linkExactActiveClass: 'active',
	routes: Routes,
	scrollBehavior() {
		return { top: 0, behavior: 'smooth' };
	},
});

router.beforeEach((to, from, next) => {
	const isLoggedIn = localStorage.getItem('token');

	if (to.meta.requiresAuth && !isLoggedIn) {
		next({ name: 'login' });
	}

	if (to.meta.requiresGuest && isLoggedIn) {
		next({ name: 'account' });
	}

	return next();
});

export default router;
