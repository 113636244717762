<script setup>
import { defineProps } from 'vue';
const props = defineProps(['isLoading']);
</script>

<template>
	<div class="loading-overlay" v-if="props.isLoading">
		<h3>جاري التحميل ...</h3>
	</div>
</template>

<style>
.loading-overlay {
	position: absolute;
	z-index: 99;
	background-color: #333333a6;
	width: 100%;
	height: 100%;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
